.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.search-result {
  padding: 13rem 0 26.7rem;
  text-align: center;
}

.photos-page .photo-container {
  padding-top: 30px;
  margin-top: 0;
}

.photos-page .photo-container h2 {
  margin-bottom: 30px;
}

.video-play-content .guest-message {
  margin-top: 26.5%;
  text-align: center;
  text-decoration: underline;
}

.page-not-found {
  padding: 6rem 6rem 33rem;
}

.page-not-found a {
  text-decoration: underline;
}

