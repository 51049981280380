.admin-login-page {
  padding: 7rem 0 21.2rem 0;
  margin: auto;
  width: 360px;
}

fieldset {
  border-width: 2px;
  border-style: groove;
  border-color: rgb(192, 192, 192);
  border-image: initial;
  min-width: min-content;
  padding: 0.8rem;
  margin: initial;
}

legend {
  width: auto;
}

input {
  display: block;
  padding: 8px;
  width: 100%;
  margin: 0 0 1rem;
}

button {
  padding: 0.4rem 1.2rem;
  margin-top: 0.4rem;
  width: 100%;
  background: blue;
  color: white;
  border: 0;
  border-radius: 0.2rem;
  cursor: pointer;
}

form {
  padding: 0 1rem;
}

input[type=checkbox] {
  display: inline-block;
  width: auto;
  margin: 0.9rem 0 1.5rem;
}

label {
  position: relative;
  top: -1px;
  margin: 0 0.7rem;
}